<template>
  <v-card>
    <v-card-title class="primary white--text">完了</v-card-title>
    <v-card-text>
      <v-container>
        <div class="d-flex align-center justify-center flex-column">
          <div class="text-body-1 ma-4">登録が完了しました。</div>
        </div>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <div class="flex-grow-1"></div>
      <v-btn color="primary darken-1" text @click="handleClose">閉じる</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'DongleRegisterComplate',
    props: {
    item: Object,
  },
  methods: {
    handleClose() {
      this.$emit('close');
    },
  },
}
</script>