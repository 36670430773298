<template>
  <div class="pa-4">
    <dongle-register-form v-if="currentProgress == 'form'"
      :inputedDongleProps="inputedDongle"
      @confirm="enterConfirm"
    />
    <v-dialog
      v-model="isDisplay"
      @input="reset"
      max-width="700"
    >
      <dongle-register-confirm v-if="currentProgress=='confirm'"
        :inputedDongleProps="inputedDongle"
        @reenter="enterForm"
        @complete="enterComplete"
        @close="closeDialog"
      />
      <dongle-register-complete v-else-if="currentProgress=='complete'"
        :item="inputedDongle"
        @close="closeDialog"
      />
    </v-dialog>
  </div>
</template>

<script>

import DongleRegisterConfirm from '@/components/DongleRegisterConfirm.vue';
import DongleRegisterComplete from '@/components/DongleRegisterComplete.vue';
import DongleRegisterForm from '@/components/DongleRegisterForm.vue';

const progressList = {
  form: 'form',
  confirm: 'confirm',
  complate: 'complete'
};

export default {
  name: 'DongleRegister',
  components: {
    DongleRegisterForm,
    DongleRegisterConfirm,
    DongleRegisterComplete
  },
  data() {
    return {
      inputedDongle: null,
      currentProgress: progressList.form,
    };
  },
  methods: {
    reset() {
      this.enterForm();
    },
    enterForm(inputedItem = null) {
      this.inputedDongle = inputedItem;
      this.currentProgress = progressList.form;
    },
    enterConfirm(inputedItem) {
      this.inputedDongle = inputedItem;
      this.currentProgress = progressList.confirm;
    },
    enterComplete() {
      this.inputedDongle = null;
      this.currentProgress = progressList.complate;
    },
    closeDialog() {
      // this.inputedDongle = null;
      this.enterForm();
    },
  },
  computed: {
    isDisplay: {
      get() {
        // 入力フォーム以外はダイアログで表示
        return this.currentProgress !== progressList.form;
      },
      set(progress) {
        this.currentProgress = progress;
      }
    }
  },
}
</script>