<template>
  <v-form>
    <v-card>
      <v-card-title class="primary white--text">入力確認</v-card-title>
      <v-card-text class="mt-4">
        <v-container>
          <v-row justify="center">
            <v-col>
              <v-data-table
                :items="inputedDongle"
                :headers="headers"
                hide-default-header
                hide-default-footer
                class="elevation-1"
              >
                <template v-slot:[`item.inputValue`]="{ item }">
                  <div style="white-space: pre;">{{ item.inputValue }}</div>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn color="primary darken-1" text @click="back">戻る</v-btn>
        <v-btn color="warning darken-1" text @click="save">OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>
<script>
export default {
  name: 'DongleRegisterConfirm',
  props: {
    inputedDongleProps: Object,
  },
  data() {
    return {
      headers: [
        { text: '項目', value: 'itemValue'},
        { text: '入力値', value: 'inputValue'},
      ],
      inputedDongle: this.initInputedDongle(),
    }
  },
  methods: {
    back() {
      this.$emit('reenter', this.inputedDongleProps);
    },
    async save() {
      try {
        // ドングルの登録・発送までの状態にするための値を設定する
        const now = this.$firebase.firestore.FieldValue.serverTimestamp();
        let dongle = this.getDongleForRegistration();

        dongle.created = now;
        dongle.modified = now;
        dongle.type = 'dongle';
        dongle.status = 3;
        dongle.lot_id = this.inputedDongleProps.lotId;
        dongle.center_id = this.$store.state.user.role.startsWith('center')
          ? this.$store.state.user.org_id : null;
        dongle.arrived_staff_id = this.$store.state.user.id;
        dongle.arrived = now;
        dongle.received2 = now;
        dongle.received2_staff_id = this.$store.state.user.id;
        dongle.shipped = now;
        dongle.shipped_staff_id = this.$store.state.user.id;
        dongle.order_org_id = this.inputedDongleProps.customerOrgId;
        dongle.ec_order_id = this.inputedDongleProps.ecOrderId;

        let orgsRef = this.$db.doc(`customer_orgs/${this.inputedDongleProps.customerOrgId}`);
        let orgsDoc = await orgsRef.get();
        const orgsData = await orgsDoc.data();
        dongle.reception_center_id = orgsData.reception_id;

        // ドングルの登録
        const deviceRefForRegister = this.$db.collection('devices');
        await deviceRefForRegister.add(dongle);
        // 在庫の追加
        await orgsRef.update({ dongle_stock_quantity: this.$firebase.firestore.FieldValue.increment(1) });
      } catch (e) {
        this.$root.$alert('エラー', 'ドングルの登録に失敗しました。' + e);
        console.error(e);
      }

      this.$emit('complete');
    },
    initInputedDongle() {
      return [
        { itemValue: 'ロットID', inputValue: '' },
        { itemValue: '発送先医療機関', inputValue: '' },
        { itemValue: '販売事業者注文ID', inputValue: '' },
      ];
    },
    getDongleForRegistration() {
      return {
        type: 'none',
        device_id: null,
        center_id: null,
        reception_center_id : null,
        produced:	null,
        arrived: null,
        arrived_staff_id: null,
        shipped: null,
        shipped_staff_id: null,
        received1: null,
        received1_staff_id: null,
        received2: null,
        received2_staff_id: null,
        patient_attached: null,
        patient_attached_staff_id: null,
        patient_id: null,
        collected: null,
        device_collected: null,
        device_collected_staff_id: null,
        log_collected: null,
        log_collected_staff_id: null,
        device_uploaded: null,
        device_uploaded_staff_id: null,
        device_upload_error_info: null,
        log_uploaded: null,
        log_uploaded_staff_id: null,
        log_upload_error_info: null,
        disposed: null,
        disposed_staff_id: null,
        order_org_id: null,
        order_id: null,
        analysis_id: null,
        is_lost: false,
        remarks: '',
        created: null,
        modified: null,
        deleted: null,
        expiration_date: null,
        keeped: null,
        lot_id: null,
      };
    },
    convertInputedDongle(item) {
      return [
        { itemValue: 'ロットID', inputValue: item.lotId},
        { itemValue: '発送先医療機関', inputValue: item.customerOrgName },
        { itemValue: '販売事業者注文ID', inputValue: item.ecOrderId },
      ]
    },

  },
  mounted() {
    let inputedDongle = this.initInputedDongle();
    if (this.inputedDongleProps != null) {
      inputedDongle = this.convertInputedDongle(this.inputedDongleProps);
    }
    this.inputedDongle = inputedDongle;
  },
}
</script>