<template>
  <v-form
    ref="form"
    lazy-validation
    v-model="valid"
  >
    <v-card>
      <v-card-title class="primary white--text">通信用ドングル／ソフト入荷・出荷記録</v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <v-text-field
                v-model="dongleFormValue.lotId"
                label="ロットID(必須)"
                ref="lotIdTextField"
                :rules="rules.lotId"
                @input="handleInputDeviceId"
                required
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                v-model="dongleFormValue.customerOrg"
                :items="customerOrgSelectItems"
                label="発送先医療機関(必須)"
                :rules="rules.customerOrgId"
                return-object
                required
                dense
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="dongleFormValue.ecOrderId"
                label="販売事業者注文ID"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn
          color="warning darken-1"
          text
          :disabled="!valid"
          @click="handleConfirm"
        >
          入荷・出荷を記録する
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
export default {
  name: 'DongleRegisterForm',
  props: {
    inputedDongleProps: Object,
  },
  data() {
    return {
      valid: true,
      dongleFormValue: {
        lotId: '',
        customerOrg: {
          text: '',
          value: ''
        },
        ecOrderId: null,
      },
      customerOrgSelectItems: [],
      rules: {
        lotId: [
          v => !!v || '入力は必須です。',
          v => this.checkDigit(v) || '数値を入力してください。',
          v => v.length === 5 || '5桁の数値を入力してください。'
        ],
        customerOrgId: [
          v => this.checkSelected(v) || '選択は必須です。',
        ],
      },
    }
  },
  methods: {
    checkDigit(v) {
      let result = true;
      v.split('').forEach((digit) => {
        if (digit === '0') {
          return;
        }
        if (!parseInt(digit)) {
          result = false;
        }
      });
      return result;
    },
    checkSelected(v) {
      let result = false;
      if (v.text || v.value) {
        result = true;
      }
      return result;
    },
    handleInputDeviceId() {
      this.checkRotId();
    },
    async checkRotId() {
      const textField = this.$refs['lotIdTextField'];
      this.clearErrorMessages(textField);
      //const lotId = this.dongleFormValue.lotId;

      // バリデーションがエラーの場合は終了
      if (!textField.validate()) return;


    },
    clearErrorMessages(field) {
      if (field && field.errorMessages) {
        field.errorMessages.splice(0);
      }
    },
    async setRegisterForm(dongle) {
      // フォームの入力値を設定
      if (dongle !== null) {
        if (dongle.lotId !== undefined) {
          this.dongleFormValue.lotId = dongle.lotId;
        }
        if (dongle.customerOrgId !== undefined) {
          this.dongleFormValue.customerOrg.value = dongle.customerOrgId;
        }
        if (dongle.customerOrgName !== undefined) {
          this.dongleFormValue.customerOrg.text = dongle.customerOrgName;
        }
        if (dongle.ecOrderId !== undefined) {
          this.dongleFormValue.ecOrderId = dongle.ecOrderId;
        }
      }
      // 選択フォームの選択肢を設定
      let orgsRef = this.$db.collection('customer_orgs')
        .where('deleted', '==', null)
        .where('center_id', '==', this.$store.state.user.org_id);
      const orgsQuerySnapshot = await orgsRef.get();
      const orgs = [];
      orgsQuerySnapshot.forEach((orgDoc) => {
        const orgData = orgDoc.data();
        orgs.push({
          text: orgData.name,
          value: orgDoc.id,
        });
      });
      this.customerOrgSelectItems = JSON.parse(JSON.stringify(orgs));
    },
    handleConfirm() {
      if (!this.$refs.form.validate()) return;
      this.$emit('confirm', {
        lotId: this.dongleFormValue.lotId,
        customerOrgId: this.dongleFormValue.customerOrg.value,
        customerOrgName: this.dongleFormValue.customerOrg.text,
        ecOrderId: this.dongleFormValue.ecOrderId,
      });
    }
  },
  mounted() {
    this.setRegisterForm(this.inputedDongleProps);
  },
}
</script>